// KeynomeManagerDownload.jsx
import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import DownloadListItem from './DownloadListItem';

const styles = {
  profileHeader: {
    backgroundColor: '#eee',
    padding: '1px 0px 1px 10px'
  },
  apiKeysList: {
    width: '100%',
    bgcolor: 'background.paper',
    paddingTop: '0',
    paddingBottom: '0'
  },
  instructionsContainer: {
    padding: "10px 20px 20px"
  },
  instructionsTitle: {
    fontWeight: "bold",
    mb: 1
  },
  codeBlock: {
    backgroundColor: "#eee",
    padding: "2px 6px",
    borderRadius: "5px"
  },
  listItem: {
    marginBottom: "8px"
  },
  orderedList: {
    margin: 0,
    paddingLeft: "20px"
  }
};

function KeynomeManagerDownload() {
  return (
    <>
      <Box sx={styles.profileHeader}>
        <p><b>Download Keynome Manager</b> <small style={{ color: "orange" }}>(coming soon)</small></p>
      </Box>
      <Box>
        <List sx={styles.apiKeysList}>
          <DownloadListItem platform="windows" />
          <DownloadListItem platform="ubuntu_20_04" />
          <DownloadListItem platform="ubuntu_22_04" />
          <DownloadListItem platform="macos" />
        </List>
      </Box>
      <Box sx={styles.instructionsContainer}>
        <Typography sx={styles.instructionsTitle}>
          Installation Instructions
        </Typography>
        <ol style={styles.orderedList}>
          <li style={styles.listItem}>
            Download the appropriate <code style={styles.codeBlock}>keynome-manager</code> CLI tool for your target platform
          </li>
          <li style={styles.listItem}>
            Run <code style={styles.codeBlock}>chmod +x keynome-manager</code>
          </li>
          <li style={styles.listItem}>
            Add <code style={styles.codeBlock}>keynome-manager</code> to your <code style={styles.codeBlock}>PATH</code>
          </li>
          <li>
            Confirm your installation with <code style={styles.codeBlock}>keynome-manager --help</code>
          </li>
        </ol>
      </Box>
    </>
  );
}

export default KeynomeManagerDownload;