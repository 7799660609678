import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tab,
  Tabs,
} from '@mui/material';

import DatasetCreation from '../Docs/DatasetCreation';
import SequencingFileUpload from '../Docs/SequencingFileUpload';
import MetadataViewing from '../Docs/MetadataViewing';
import OrganismIDViewing from '../Docs/OrganismIDViewing';
import PredictiveASTViewing from '../Docs/PredictiveASTViewing';

const styles = {
  dialogTitle: {
    backgroundColor: "#eee"
  },
  dialogContent: {
    display: 'flex',
    padding: 0,
    minHeight: '60vh',
    maxHeight: '80vh',
    overflow: 'hidden'
  },
  tabPanel: {
    padding: "40px",
    flexGrow: 1,
    overflowY: 'auto'
  },
  tabs: {
    borderRight: 1,
    borderColor: 'divider',
    minWidth: '250px',
    maxWidth: '250px',
    flex: '0 0 250px',
    backgroundColor: '#f5f5f5',
    overflowY: 'auto',
    '& .MuiTab-root': {
      alignItems: 'flex-start',
      textAlign: 'left',
      fontWeight: 'bold',
      maxWidth: 'none',
      '& .MuiTab-wrapper': {
        whiteSpace: 'normal',
      }
    },
    '& .nested-tab': {
        paddingLeft: '32px',
        fontSize: '0.9rem'
      },
      '& .section-header': {
        color: 'text.secondary',
        pointerEvents: 'none',
        fontSize: '0.75rem',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        paddingTop: '16px',
        fontWeight: 'normal'
      }
  },
  dialogActions: {
    backgroundColor: "#eee"
  }
};

const TabPanel = ({ children, value, index }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    sx={styles.tabPanel}
  >
    {value === index && children}
  </Box>
);

function DocumentationDialog({ isOpen, onClose }) {
  const [selectedTab, setSelectedTab] = useState(1);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle sx={styles.dialogTitle}>
        Keynome Cloud Documentation
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Tabs
          orientation="vertical"
          value={selectedTab}
          onChange={(_, newValue) => setSelectedTab(newValue)}
          sx={styles.tabs}
        >
          <Tab label="Getting Started" className="section-header" disabled />
          <Tab label="Create a Sequencing Dataset" className="nested-tab" />
          <Tab label="Upload Sequencing Data" className="nested-tab" />
          
          <Tab label="Viewing Results" className="section-header" disabled />
          <Tab label="Dataset, Sample, and Analysis Metadata" className="nested-tab" />
          <Tab label="Organism ID Results" className="nested-tab" />
          <Tab label="Predictive AST Results" className="nested-tab" />
        </Tabs>

        <TabPanel value={selectedTab} index={1}>
            <DatasetCreation />
        </TabPanel>

        <TabPanel value={selectedTab} index={2}>
          <SequencingFileUpload />
        </TabPanel>

        <TabPanel value={selectedTab} index={4}>
          <MetadataViewing />
        </TabPanel>

        <TabPanel value={selectedTab} index={5}>
          <OrganismIDViewing />
        </TabPanel>

        <TabPanel value={selectedTab} index={6}>
          <PredictiveASTViewing /> 
        </TabPanel>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button 
          variant="contained" 
          color="primary" 
          sx={{ marginRight: "20px" }}
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DocumentationDialog;