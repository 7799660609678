const sequencerSerialNumberMetadata = {
    minion: {
        prefix: "MN",
        placeholder: "XXXXX"
    },
    gridion: {
        prefix: "GXB",
        placeholder: "XXXXX-XX"
    },
    p2: {
        prefix: "P2S",
        placeholder: "XXXXX-XX"
    },
    p24: {
        prefix: "PC",
        placeholder: "XXXXX-XX"
    }
}

const sequencerLabelToNameByPlatform = {
    ont: {
        minion: "MinION",
        gridion: "GridION",
        p2: "PromethION 2",
        p24: "PromethION 24"
    },
    illumina: {
        iseq_100: "iSeq 100",
        miniseq: "MiniSeq",
        miseq: "MiSeq",
        nextseq_500: "NextSeq 500",
        nextseq_550: "NextSeq 550",
        nextseq_1000: "NextSeq 1000",
        nextseq_2000: "NextSeq 2000",
        hiseq_1000: "HiSeq 1000",
        hiseq_2000: "HiSeq 2000",
        novaseq_6000: "NovaSeq 6000",
        novaseq_x: "NovaSeq X"
    }
}

const sequencerLabelToName = {
    ...sequencerLabelToNameByPlatform["ont"],
    ...sequencerLabelToNameByPlatform["illumina"]
};

const flowcellLabelToNameByPlatform = {
    ont: {
        'FLO-MIN106': "FLO-MIN106",
        'FLO-MIN107': "FLO-MIN107",
        'FLO-MIN110': "FLO-MIN110",
        'FLO-MIN114': "FLO-MIN114",
        'FLO-PRO002': "FLO-PRO002",
        'FLO-PRO114M': "FLO-PRO114M",
    },
    illumina: {
        iseq_100: "iSeq 100",
        miniseq_high_output: "MiniSeq High Output",
        nextseq_500_high_output: "NextSeq 500 High Output",
        nextseq_500_mid_output: "NextSeq 500 Mid Output",
        nextseq_550_high_output: "NextSeq 550 High Output",
        nextseq_550_mid_output: "NextSeq 550 Mid Output",
        hiseq_3000: "HiSeq 3000",
        hiseq_4000: "HiSeq 4000",
        hiseq_x: "HiSeq X",
        novaseq_6000_s1: "NovaSeq 6000 S1",
        novaseq_6000_s2: "NovaSeq 6000 S2",
        novaseq_6000_s3: "NovaSeq 6000 S3",
    }
}

const flowcellLabelToName = {
    ...flowcellLabelToNameByPlatform["ont"],
    ...flowcellLabelToNameByPlatform["illumina"]
}

const barcodingKitLabelToNameByPlatform = {
    ont: {
        'EXP-PBC096': "EXP-PBC096",
        'SQK-RBK004': "SQK-RBK004",
        'SQK-RBK110.96': "SQK-RBK110.96",
        'SQK-RBK114.24': "SQK-RBK114.24",
        'SQK-RBK114.96': "SQK-RBK114.96",
        'SQK-RPB004': "SQK-RPB004",
    },
    illumina: {
        nextera_xt_v2: 'Nextera XT v2',
        nextera_xt_v3: 'Nextera XT v3',
        nextera_dna_flex_v2: "Nextera DNA Flex v2",
        truseq_dna_pcr_free: "TruSeq DNA PCR-Free",
        truseq_dna_and_rna_single: "TruSeq DNA and RNA Single",
    }
}

const barcodingKitLabelToName = {
    ...barcodingKitLabelToNameByPlatform["ont"],
    ...barcodingKitLabelToNameByPlatform["illumina"]
}

const libraryPrepKitLabelToNameByPlatform = {
    ont: {
        'SQK-LSK109': "SQK-LSK109",
        'SQK-LSK110': "SQK-LSK110",
        'SQK-LSK114': "SQK-LSK114",
        'SQK-RBK004': "SQK-RBK004",
        'SQK-RBK110.96': "SQK-RBK110.96",
        'SQK-RBK114.24': "SQK-RBK114.24",
        'SQK-RBK114.96': "SQK-RBK114.96",
        'SQK-RPB004': "SQK-RPB004"
    },
    illumina: {
        nextera_dna_flex: "Nextera DNA Flex",
        truseq_dna_pcr_free: "TruSeq DNA PCR-Free",
        truseq_dna_nano: "TruSeq DNA Nano",
        truseq_dna_flex: "TruSeq DNA Flex",
        nextera_xt_dna: "Nextera XT DNA",
    }
}

const libraryPrepKitLabelToName = {
    ...libraryPrepKitLabelToNameByPlatform["ont"],
    ...libraryPrepKitLabelToNameByPlatform["illumina"]
}

export {
    sequencerLabelToNameByPlatform,
    sequencerLabelToName,
    flowcellLabelToNameByPlatform,
    flowcellLabelToName,
    barcodingKitLabelToNameByPlatform,
    barcodingKitLabelToName,
    libraryPrepKitLabelToNameByPlatform,
    libraryPrepKitLabelToName,
    sequencerSerialNumberMetadata
}
