import DownloadIcon from '@mui/icons-material/Download';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUbuntu, faApple, faWindows } from '@fortawesome/free-brands-svg-icons';

const styles = {
    iconContainer: {
      padding: "13px 20px"
    },
    labelContainer: {
      padding: "13px 20px"
    },
    label: {
      color: "gray",
      fontWeight: "bold"
    },
    downloadContainer: {
      textAlign: "right",
      padding: "5px 20px"
    },
    downloadButton: {
      color: "primary.main"
    },
    container: {
        borderBottom: '2px solid #e0e0e0'
    }
  };

const getPlatformDetails = (platform) => {
    switch (platform?.toLowerCase()) {
        case 'ubuntu_20_04':
            return {
                icon: faUbuntu,
                label: 'Ubuntu (20.04)',
            };
        case 'ubuntu_22_04':
            return {
                icon: faUbuntu,
                label: 'Ubuntu (22.04)',
            };
        case 'macos':
            return {
                icon: faApple,
                label: 'MacOS',
            };
        default:
            return {
                icon: faWindows,
                label: 'Windows',
            };
    }
};

const DownloadListItem = ({ platform }) => (
    <Box sx={styles.container}>
      <Grid container>
        <Grid item xs={3} sx={styles.iconContainer}>
          <FontAwesomeIcon icon={getPlatformDetails(platform).icon} size="lg" />
        </Grid>
        <Grid item xs={7} sx={styles.labelContainer}>
          <Typography sx={styles.label}>{getPlatformDetails(platform).label}</Typography>
        </Grid>
        <Grid item xs={2} sx={styles.downloadContainer}>
          <IconButton
            disabled
            sx={styles.downloadButton}
            aria-label={`download for ${platform}`}
            onClick={() => {/* TODO: Add download handler */}}
          >
            <DownloadIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );

export default DownloadListItem;