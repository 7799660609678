import React from 'react';
import {
    Box,
    Grid,
    InputAdornment,
    TextField,
} from '@mui/material';

import FlowCellSelect from './FlowCellSelect';
import SequencerSelect from './SequencerSelect';
import BarcodingKitSelect from './BarcodingKitSelect';
import PrepKitSelect from './PrepKitSelect';

import { sequencerSerialNumberMetadata } from './canonicalizeMetadata';

function CreateONTRunForm(props) {
    const {
        sequencer,
        setSequencer,
        sequencerSerialNumber,
        setSequencerSerialNumber,
        sequencerSerialNumberIsValid,
        flowCell,
        setFlowCell,
        flowCellSerialNumber,
        flowCellSerialNumberIsValid,
        setFlowCellSerialNumber,
        barcodingKit,
        setBarcodingKit,
        libraryPrepKit,
        setLibraryPrepKit,
        platform
    } = props;

    const sequencerIsset = sequencer !== '' && sequencer !== 'unknown';
    const sequencerSerialNumberContent = (
        <TextField
            error={sequencerIsset && !sequencerSerialNumberIsValid}
            placeholder={sequencerSerialNumberMetadata[sequencer] ? sequencerSerialNumberMetadata[sequencer].placeholder : null}
            helperText={!sequencerIsset ? null : "Optional"}
            variant="standard"
            fullWidth
            label="Serial Number"
            value={sequencerSerialNumber}
            onChange={(event) => {
                setSequencerSerialNumber(event.target.value.toUpperCase());
            }}
            disabled={!sequencerIsset}
            slotProps={{
                input: {
                    startAdornment: <InputAdornment position="start">{sequencerSerialNumberMetadata[sequencer] ? sequencerSerialNumberMetadata[sequencer].prefix : null}</InputAdornment>
                }
            }}
        />
    )

    const flowCellIsset = flowCell !== '' && flowCell !== 'unknown';
    const flowCellSerialNumberContent = (
        <TextField
            error={flowCellIsset && !flowCellSerialNumberIsValid}
            placeholder={!flowCellIsset ? null : 'X12345'}
            helperText={!flowCellIsset ? null : "Optional"}
            variant="standard"
            fullWidth
            label="Serial Number"
            value={flowCellSerialNumber}
            disabled={!flowCellIsset}
            slotProps={{
                input: {
                    startAdornment: <InputAdornment position="start">{(!flowCellIsset || flowCell === 'other') ? null : (flowCell.includes("PRO") ? 'PA' : 'FA')}</InputAdornment>
                }
            }}
            onChange={(event) => {
                setFlowCellSerialNumber(event.target.value.toUpperCase());
            }}
        />
    );

    return (
        <>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <SequencerSelect sequencer={sequencer} setSequencer={setSequencer} platform={platform} /> 
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 5px"}}>
                    {sequencerSerialNumberContent}
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <FlowCellSelect flowCell={flowCell} setFlowCell={setFlowCell} platform={platform} />
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 5px"}}>
                    {flowCellSerialNumberContent}
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <BarcodingKitSelect barcodingKit={barcodingKit} setBarcodingKit={setBarcodingKit} platform={platform} />
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <PrepKitSelect libraryPrepKit={libraryPrepKit} setLibraryPrepKit={setLibraryPrepKit} platform={platform} />
                </Box>
            </Grid>
            </>
    )
}

export default CreateONTRunForm;