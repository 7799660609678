import React from 'react';
import {
    Box,
    Grid,
} from '@mui/material';

import FlowCellSelect from './FlowCellSelect';
import SequencerSelect from './SequencerSelect';
import BarcodingKitSelect from './BarcodingKitSelect';
import PrepKitSelect from './PrepKitSelect';

function CreateIlluminaRunForm(props) {
    const {
        sequencer,
        setSequencer,
        flowCell,
        setFlowCell,
        barcodingKit,
        setBarcodingKit,
        libraryPrepKit,
        setLibraryPrepKit,
        platform
    } = props;

    return (
        <>
        <Grid item xs={6}>
            <Box sx={{padding: "15px 10px"}}>
                <SequencerSelect sequencer={sequencer} setSequencer={setSequencer} platform={platform} /> 
            </Box>
        </Grid>
        <Grid item xs={6}>
            <Box sx={{padding: "15px 10px"}}>
                <FlowCellSelect flowCell={flowCell} setFlowCell={setFlowCell} platform={platform} />
            </Box>
        </Grid>
        <Grid item xs={6}>
            <Box sx={{padding: "15px 10px"}}>
                <BarcodingKitSelect barcodingKit={barcodingKit} setBarcodingKit={setBarcodingKit} platform={platform} />
            </Box>
        </Grid>
        <Grid item xs={6}>
            <Box sx={{padding: "15px 10px"}}>
                <PrepKitSelect libraryPrepKit={libraryPrepKit} setLibraryPrepKit={setLibraryPrepKit} platform={platform} />
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Box sx={{padding: "50px 10px"}}>
            </Box>
        </Grid>
        </>
    )
}

export default CreateIlluminaRunForm;