import React from 'react';
import { Box, Typography } from '@mui/material';

function OrganismIDViewing() {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Organism ID Results
      </Typography>
      
      <Typography paragraph>
        Organism identification results provide detailed information about organisms detected in your sequencing data, including supporting metrics and confidence indicators.
      </Typography>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Prerequisites
      </Typography>
      <Typography component="div" paragraph>
        <ul>
          <li>Created sequencing dataset</li>
          <li>FASTQ files uploaded and analysis completed</li>
        </ul>
      </Typography>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Viewing Organism ID Results
      </Typography>
      <Typography component="div" paragraph>
        <ol>
          <li>
            Click on your target dataset in the Sequencing Datasets list
          </li>
          <li>
            Click on your target sample in the Samples in Dataset list once analysis is complete
          </li>
          <li>
            For each identified organism, you will see:
            <ul>
              <li><strong>Positive / Subthreshold Call:</strong> Indicates whether the organism signal strength meets threshold requirements for confident identification (ONT only)</li>
              <li><strong>Keynome ID (KID) Value:</strong> Breadth based organism presence signal metric based on proprietary DZD algorithm (ONT only)</li>
              <li><strong>MBs / Reads to Organism:</strong> Volume of sequencing data attributed to particular organism</li>
              <li><strong>Reference Coverage Breadth / Depth:</strong> Breadth and depth of coverage of closest matching reference in proprietary DZD reference database for particular organism (ONT only)</li>
              <li><strong>Raw Assembly Length / Contigs:</strong> Length and number of contigs in unpolished de novo assembly attributed to particular organism</li>
            </ul>
          </li>
          <li>Click the "Change Results Display" button to change the number of organisms displayed and toggle on/off specific organism ID metrics</li>
        </ol>
      </Typography>

      <Box sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1, mt: 2 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
          Note
        </Typography>
        <Typography variant="body2">
          Results are only available after sample analysis has completed successfully.
        </Typography>
      </Box>
    </>
  );
}

export default OrganismIDViewing;