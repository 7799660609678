import React from 'react';
import { Box, Typography, Divider, Alert } from '@mui/material';

function SequencingFileUpload() {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Uploading Sequencing Data
      </Typography>
      
      <Typography paragraph>
        After creating a dataset, you can upload your sequencing files. The upload process runs in the background, allowing you to continue working while files transfer.
      </Typography>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Prerequisites
      </Typography>
      <Typography component="div" paragraph>
        <ul>
          <li>Created sequencing dataset</li>
          <li>FASTQ files ready for upload</li>
        </ul>
      </Typography>
      <Alert 
        severity="warning" 
        variant="outlined"
        sx={{ 
          mt: -1,
          mb: 2,
          bgcolor: 'background.paper'
        }}
      >
        FASTQ files uploaded via the browser must be Gzip compressed with a .fastq.gz extension
      </Alert>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Uploading ONT Sequencing Files
      </Typography>
      <Typography component="div" paragraph>
        <ol>
          <li>
            Click on your target dataset in the Sequencing Datasets list
          </li>
          <li>
            In the upload dialog:
            <ul>
              <li>Click the dropzone area or drag and drop your FASTQ file</li>
              <li>Verify your file appears in the staged files list</li>
              <li>Click the upload icon to begin the transfer</li>
            </ul>
          </li>
          <li>
            Click the dialog close button to continue working while upload proceeds
          </li>
        </ol>
      </Typography>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Uploading Illumina Sequencing Files
      </Typography>
      <Typography component="div" paragraph>
        <ol>
          <li>
            Click on your target dataset in the Sequencing Datasets list
          </li>
          <li>
            In the upload dialog:
            <ul>
              <li>Click the dropzone area or drag and drop your paired FASTQ files</li>
              <li>Verify your the paired R1 and R2 files appear in the staged files list</li>
              <li>Click the upload icon to begin the transfer</li>
            </ul>
          </li>
          <li>
            Click the dialog close button to continue working while upload proceeds
          </li>
        </ol>
      </Typography>
      <Alert 
        severity="warning" 
        variant="outlined"
        sx={{ 
          mt: -1,
          mb: 2,
          bgcolor: 'background.paper'
        }}
      >
        Keynome Cloud will automatically detect and pair R1 and R2 files based on the file names. Both the R1 and R2 files must be staged for upload to proceed. Paired end FASTQ files must be identifiable via _R1/_R2 or _1/_2 file name endings prior to the .fastq.gz extension.
      </Alert>

      <Box sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1, mt: 2 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
          Notes
        </Typography>
        <Typography variant="body2" paragraph>
          File uploads continue in the background even after closing the dialog. You can monitor upload progress in the Sequencing Datasets table.
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body2">
          For large files, the upload may take several minutes to complete. Avoid closing the browser during upload to prevent interrupting data transfer.
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body2">
          Sample names are inferred from the FASTQ file names. Each sample in a sequencing dataset must have a unique name - upload will be prevented if a duplicate sample name is detected.
        </Typography>
      </Box>
    </>
  );
}

export default SequencingFileUpload;