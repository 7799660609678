import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';

import SequencingYieldPieChart from './SequencingYieldPieChart';

const styles = {
  lockIcon: {
    padding: '2px',
    marginLeft: '4px',
    color: 'rgba(0, 0, 0, 0.4)',
    marginTop: '-3px',
  },
  dialogTitle: {
    backgroundColor: '#eee',
    display: 'flex',
    alignItems: 'center',
  },
  dialogContent: {
    marginTop: '20px',
    padding: '20px 40px',
  },
  dialogActions: {
    backgroundColor: '#eee',
  },
};

const getTimeElapsed = (start, end) => {
  const diff = new Date(end) - new Date(start);
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);
  return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
};

function DetailedSequencingYieldSummary(props) {
  const {
    analysis
  } = props;

  const { getAccessTokenSilently } = useAuth0();

  const [isHumanDataDialogOpen, setIsHumanDataDialogOpen] = useState(false);
  const [sequencingFileData, setSequecingFileData] = useState(null);

  /**
   * On change to analysis, retrieve data for associated sequencing file
   * to report on human DNA deletion
   */
  useEffect(() => {
    if (!analysis) {
      return;
    }
  
    const fetchSequencingData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });
  
        const execResponse = await fetch(
          `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/analysis_executions/${analysis.attributes.flow_execution_id}`,
          {
            method: 'GET',
            headers,
          }
        );
  
        if (!execResponse.ok) {
          throw new Error(`Request failed with ${execResponse.status} (${execResponse.statusText})`);
        }
  
        const execData = await execResponse.json();
        const fastqResponse = await fetch(
          `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/concatenated_fastqgzs/${execData.data.attributes.concatenated_fastqgz_id}`,
          {
            method: 'GET',
            headers,
          }
        );
  
        if (!fastqResponse.ok) {
          throw new Error(`Request failed with ${fastqResponse.status} (${fastqResponse.statusText})`);
        }
  
        const fastqData = await fastqResponse.json();
        setSequecingFileData(fastqData.data);
      } catch (error) {
        datadogRum.addError(error);
      }
    };
  
    fetchSequencingData();
  }, [getAccessTokenSilently, analysis]);

  if (!analysis) {
    return
  }

  const isIllumina = analysis.attributes.raw_total_megabases_r2 !== null
 
  const totalMegabases = isIllumina ? 
    (analysis.attributes.raw_total_megabases + analysis.attributes.raw_total_megabases_r2) : 
    analysis.attributes.raw_total_megabases

  const downsampledMegabases = isIllumina ?
        ((analysis.attributes.qc_filtered_megabases + analysis.attributes.qc_filtered_megabases_r2) - (analysis.attributes.downsampled_megabases + analysis.attributes.downsampled_megabases_r2)) :
        (analysis.attributes.raw_total_megabases - analysis.attributes.downsampled_megabases)

  const downsampledReads = isIllumina ?
        (analysis.attributes.qc_filtered_reads - analysis.attributes.downsampled_reads) :
        (analysis.attributes.raw_total_reads - analysis.attributes.downsampled_reads)

  const qcFilteredMegabases = isIllumina ?
        ((analysis.attributes.raw_total_megabases + analysis.attributes.raw_total_megabases_r2) - (analysis.attributes.qc_filtered_megabases + analysis.attributes.qc_filtered_megabases_r2)) :
        (analysis.attributes.downsampled_megabases - analysis.attributes.qc_filtered_megabases)

  const qcFilteredReads = isIllumina ?
        (analysis.attributes.raw_total_reads - analysis.attributes.qc_filtered_reads) :
        (analysis.attributes.downsampled_reads - analysis.attributes.qc_filtered_reads)

  const humanReadsRemovedMegabases = isIllumina ?

        ((analysis.attributes.downsampled_megabases + analysis.attributes.downsampled_megabases_r2) - (analysis.attributes.human_reads_removed_megabases + analysis.attributes.human_reads_removed_megabases_r2)) :
        (analysis.attributes.qc_filtered_megabases - analysis.attributes.human_reads_removed_megabases)

  const humanReadsRemovedReads = isIllumina ?
        (analysis.attributes.downsampled_reads - analysis.attributes.human_reads_removed_reads) :
        (analysis.attributes.qc_filtered_reads - analysis.attributes.human_reads_removed_reads)

  const analyzedMegabases = isIllumina ? 
        (analysis.attributes.human_reads_removed_megabases + analysis.attributes.human_reads_removed_megabases_r2) :
        analysis.attributes.human_reads_removed_megabases
        
  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <Grid container>
          <Grid item xs={12}>
            <p><b>SEQUENCING YIELD</b></p>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: '35px 40px' }}>
          {sequencingFileData?.attributes.human_dna_deleted_at && (
              <p style={{ color: "#5267AA" }}>
                <b>Removed as Human:</b>
                {' '}
                {(sequencingFileData.attributes.human_dna_megabases + (sequencingFileData.attributes.human_dna_megabases_r2 || 0)).toLocaleString()} MBs ({(sequencingFileData.attributes.human_dna_reads + (sequencingFileData.attributes.human_dna_reads_r2 || 0)).toLocaleString()} read{isIllumina ? ' pairs' : 's'}) 
                {(sequencingFileData.attributes.human_dna_megabases > 0 || (sequencingFileData.attributes.human_dna_megabases_r2 || 0) > 0) && (
                  <IconButton
                    size="small"
                    onClick={() => setIsHumanDataDialogOpen(true)}
                    sx={styles.lockIcon}
                >
                  <LockIcon fontSize="small" />
                </IconButton>
                )}
              </p>
            )}
            <p>
              <b>Input Data:</b>
              {' '}
              {totalMegabases.toLocaleString() || '-'} MBs ({analysis?.attributes.raw_total_reads.toLocaleString() || '-'} read{isIllumina ? ' pairs' : 's'})
            </p>
            <Divider />
            <p style={{color: "gray", marginLeft: "40px"}}>
              <b>Removed by Downsampling:</b>
              {' '}
              {downsampledMegabases.toLocaleString()} MBs ({downsampledReads.toLocaleString()} read{isIllumina ? ' pairs' : 's'})
            </p>
            <p style={{color: "#FF5326", marginLeft: "40px"}}>
              <b>Removed as Low Quality:</b>
              {' '}
              {qcFilteredMegabases.toLocaleString()} MBs ({qcFilteredReads.toLocaleString()} read{isIllumina ? ' pairs' : 's'})
            </p>
            {sequencingFileData && !sequencingFileData.attributes.human_dna_deleted_at && (
              <p style={{ color: "#5267AA", marginLeft: "40px" }}>
                <b>Removed as Human:</b>
                {' '}
                {humanReadsRemovedMegabases.toLocaleString()} MBs ({humanReadsRemovedReads.toLocaleString()} read{isIllumina ? ' pairs' : 's'})
              </p>
            )}
            <Divider />
            <p style={{color: "green"}}>
              <b>Analyzed Data:
              {' '}
              {analyzedMegabases.toLocaleString() || '-'} MBs ({analysis?.attributes.human_reads_removed_reads.toLocaleString() || '-'} read{isIllumina ? ' pairs' : 's'})</b>
            </p>
          </Box>
            </Grid>
      <Grid item xs={12} sm={6} sx={{paddingRight: '90px', paddingBottom: '20px'}}>
        {analysis ? <SequencingYieldPieChart analysisAttributes={analysis.attributes} showHuman={sequencingFileData && !sequencingFileData.attributes.human_dna_deleted_at} /> : null}
      </Grid>
        </Grid>

      <Dialog
        open={isHumanDataDialogOpen}
        onClose={() => setIsHumanDataDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={styles.dialogTitle}>
          Human DNA Removal
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Typography paragraph sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CheckCircleIcon sx={{ color: 'success.main' }} />
            <b>Human DNA identified and permanently deleted</b>
          </Typography>
          <Typography paragraph>
            Original FASTQ uploaded { sequencingFileData ? new Date(sequencingFileData.attributes.created_at).toLocaleString() : null }
          </Typography>
          <Typography paragraph>
            Human DNA permanently deleted { sequencingFileData ? new Date(sequencingFileData.attributes.human_dna_deleted_at).toLocaleString() : null }
          </Typography>
          {sequencingFileData && (
            <Typography
              paragraph
              sx={{
                color: 'text.secondary',
                fontSize: '0.875rem',
                mt: -1
              }}
            >
              (permanently deleted {getTimeElapsed(
                sequencingFileData.attributes.created_at,
                sequencingFileData.attributes.human_dna_deleted_at
              )} after upload)
            </Typography>
          )}
          {sequencingFileData && (
            <Typography paragraph>
              {(sequencingFileData.attributes.human_dna_megabases + (sequencingFileData.attributes.human_dna_megabases_r2 || 0)).toFixed(3).toLocaleString()} MBs
              ({(sequencingFileData.attributes.human_dna_reads + (sequencingFileData.attributes.human_dna_reads_r2 || 0)).toLocaleString()} reads) of human DNA removed
            </Typography>
          )}
          <Typography 
            paragraph 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              color: 'text.secondary',
              fontSize: '0.875rem'
            }}
          >
            <LockIcon fontSize="small" />
            <b>Human DNA access protection confirmed</b>
          </Typography>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginRight: '20px' }}
            onClick={() => setIsHumanDataDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DetailedSequencingYieldSummary;
