import React from 'react';

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
  } from '@mui/material';

  import {
    flowcellLabelToNameByPlatform
} from './canonicalizeMetadata';

function FlowCellSelect(props) {
    const {
        flowCell,
        setFlowCell,
        platform
    } = props;

    const options = {
        ...flowcellLabelToNameByPlatform[platform],
        ...{other: "Other"}
    }

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="flow-cell-select-label">Flow Cell</InputLabel>
            <Select
                fullWidth
                labelId="flow-cell-select-label"
                id="flow-cell-select"
                value={flowCell}
                label="Flow Cell"
                onChange={(event) => setFlowCell(event.target.value)}
            >
                {Object.keys(options).map((key) => {
                    return <MenuItem value={key}>{options[key]}</MenuItem>
                })}
            </Select>
            <FormHelperText>Optional</FormHelperText>
        </FormControl>
    );
}

export default FlowCellSelect;