import React from 'react';
import { Box, Typography, Alert } from '@mui/material';

function MetadataViewing() {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Dataset, Sample, and Analysis Metadata
      </Typography>
      
      <Typography paragraph>
        Each sequencing dataset and sample contains detailed metadata about the sequencing run and analysis process.
      </Typography>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Prerequisites
      </Typography>
      <Typography component="div" paragraph>
        <ul>
          <li>Created sequencing dataset</li>
          <li>FASTQ files uploaded and analysis completed</li>
        </ul>
      </Typography>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Viewing Metadata
      </Typography>
      <Typography component="div" paragraph>
        <ol>
          <li>
            Click on your target dataset in the Sequencing Datasets list
          </li>
          <li>
            Click on your target sample in the Samples in Dataset list once analysis is complete
          </li>
          <li>
            Dataset metadata includes:
            <ul>
              <li><strong>Name:</strong> The name of the parent dataset for this sample</li>
              <li><strong>Sequencer:</strong> The ONT or Illumina sequencer model used</li>
              <li><strong>Flow Cell:</strong> The flow cell type used for sequencing</li>
              <li><strong>Library Prep Kit:</strong> The library preparation kit used</li>
              <li><strong>Barcoding/Index Kit:</strong> The barcoding or index kit used</li>
              <li><strong>Date:</strong> Dataset creation date</li>
            </ul>
          </li>
          <li>
            Sample and analysis metadata includes:
            <ul>
              <li><strong>Sample Name:</strong> Name of the analyzed sample</li>
              <li><strong>Sample Type:</strong> Type of the analyzed sample</li>
              <li><strong>Analysis Version:</strong> Version of the Keynome Cloud pipeline used</li>
              <li><strong>Analysis Started At / Completed At:</strong> Analysis timeline and turnaround time</li>
            </ul>
          </li>
        </ol>
      </Typography>
      <Alert 
        severity="info" 
        variant="outlined"
        sx={{ 
          mt: -1,
          mb: 2,
          bgcolor: 'background.paper'
        }}
      >
        Clicking the reported Analysis Version will display detailed version regarding specific versions of Keynome Cloud subsystems utilized for organism ID and predictive AST. In addition, available resistance genes and predictive AST organism / antibiotic panels are accessible via this detailed Analysis Version view.
      </Alert>

      <Box sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1, mt: 2 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
          Note
        </Typography>
        <Typography variant="body2">
          Analysis metadata is only available after sample analysis has completed successfully.
        </Typography>
      </Box>
    </>
  );
}

export default MetadataViewing;