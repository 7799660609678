import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

function DatasetCreation() {
    return (
    <>
      <Typography variant="h6" gutterBottom>
        Creating a Sequencing Dataset
      </Typography>
      
      <Typography paragraph>
        You can create sequencing datasets for both Oxford Nanopore (ONT) and Illumina platforms. Each platform has specific required fields and validation rules.
      </Typography>
  
      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Prerequisites
      </Typography>
      <Typography component="div" paragraph>
        <ul>
          <li>Appropriate project selected in navigation bar</li>
        </ul>
      </Typography>
  
      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Creating an ONT Dataset
      </Typography>
      <Typography component="div" paragraph>
        <ol>
          <li>
            Click the <strong>Create New Dataset</strong> button in the top right of the Sequencing Datasets section
          </li>
          <li>
            In the dialog that appears:
            <ul>
              <li>Enter a unique name for your dataset (required)</li>
              <li>Select the ONT sequencer used, as appropriate</li>
              <li>Enter the sequencer serial number</li>
              <li>Select the flow cell type</li>
              <li>Enter the flow cell serial number</li>
              <li>Select the barcoding kit used</li>
              <li>Select the library preparation kit used</li>
              <li>Add an optional description</li>
            </ul>
          </li>
          <li>
            Click <strong>Create</strong> to save your dataset
          </li>
        </ol>
      </Typography>
  
      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Creating an Illumina Dataset  
      </Typography>
      <Typography component="div" paragraph>
        <ol>
          <li>
            Click the <strong>Create New Dataset</strong> button in the top right of the Sequencing Datasets section
          </li>
          <li>
            In the dialog that appears:
            <ul>
              <li>Enter a unique name for your dataset (required)</li>
              <li>Select your Illumina sequencer model</li>
              <li>Select the flow cell type</li>
              <li>Select the index kit used</li>
              <li>Select the library preparation kit used</li>
              <li>Add an optional description</li>
            </ul>
          </li>
          <li>
            Click <strong>Create</strong> to save your dataset
          </li>
        </ol>
      </Typography>
  
        <Box sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1, mt: 2 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                Notes
            </Typography>
            <Typography variant="body2" paragraph>
                The Create button will be disabled until all required fields are completed. Your new dataset will appear in the Sequencing Datasets table with an "Uploading" status once created.
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2">
                Datasets can contain data from multiple sequencing runs. When combining data from multiple runs, sequencer, flow cell, and kit information fields may be left blank.
            </Typography>
        </Box>
    </>
  );
}

export default DatasetCreation;