import React from 'react';

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
  } from '@mui/material';

  import {
    sequencerLabelToNameByPlatform
} from './canonicalizeMetadata';

function SequencerSelect(props) {
    const {
        sequencer,
        setSequencer,
        platform
    } = props;

    const options = {
        ...sequencerLabelToNameByPlatform[platform],
        ...{other: "Other"}
    }

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="sequencer-select-label">Sequencer</InputLabel>
            <Select
                fullWidth
                labelId="sequencer-select-label"
                id="sequencer-select"
                value={sequencer}
                label="Sequencer"
                onChange={(event) => setSequencer(event.target.value)}
            >
                {Object.keys(options).map((key) => {
                    return <MenuItem value={key}>{options[key]}</MenuItem>
                })}
            </Select>
            <FormHelperText>Optional</FormHelperText>
        </FormControl>
    );
}

export default SequencerSelect;