import React from 'react';
import { Box, Typography } from '@mui/material';

function PredictiveASTViewing() {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Predictive AST Results
      </Typography>
      
      <Typography paragraph>
        Predictive AST results provide antibiotic susceptibility predictions for identified organisms, with different model confidence levels available.
      </Typography>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Prerequisites
      </Typography>
      <Typography component="div" paragraph>
        <ul>
          <li>Created sequencing dataset</li>
          <li>FASTQ files uploaded and analysis completed</li>
        </ul>
      </Typography>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        Viewing Predictive AST Results
      </Typography>
      <Typography component="div" paragraph>
        <ol>
          <li>
            Click on your target dataset in the Sequencing Datasets list
          </li>
          <li>
            Click on your target sample in the Samples in Dataset list once analysis is complete
          </li>
          <li>
            For each positively identified organism, you will find:
            <ul>
              <li><strong>Qualified Models:</strong> AST predictions from models meeting DZD's highest performance thresholds</li>
            </ul>
          </li>
          <li>
            Click the "Change Results Display" button to toggle on:
            <ul>
              <li><strong>R&D Models:</strong> Display predictions from models considered mid-performing by DZD</li>
              <li><strong>Imputations:</strong> Display imputed AST calls inferred from current standards guidelines</li>
              <li><strong>Resistance Genes:</strong> Display identified resistance genes to supplement predictive AST calls</li> 
            </ul>
          </li>
        </ol>
      </Typography>

      <Box sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1, mt: 2 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
          Note
        </Typography>
        <Typography variant="body2">
          Results are only available after sample analysis has completed successfully.
        </Typography>
      </Box>
    </>
  );
}

export default PredictiveASTViewing;