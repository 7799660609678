import React, { useEffect } from "react";
import { Outlet } from 'react-router-dom';

import { useAuth0 } from "@auth0/auth0-react";

import { CircularProgress, Box } from '@mui/material';

import logo from './dzd-logo.svg';

function RequiredAuth() {
  const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      const originalUri = window.location.pathname + window.location.search;
      loginWithRedirect(
        { appState: { returnTo: originalUri } }
      );
    }
  }, [isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <img src={logo} alt="dzd-logo" />
        <CircularProgress sx={{ color: 'orange', ml: 4 }} />
      </Box>
    );
  }

  return (<Outlet />);
}

export default RequiredAuth;