import React, {useMemo, useState, useCallback, useEffect} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum  } from '@datadog/browser-rum';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import ontLogo from '../ont_logo.svg';
import illuminaLogo from '../illumina_logo.svg';
import CreateONTRunForm from './CreateONTRunForm';
import CreateIlluminaRunForm from './CreateIlluminaRunForm';
import { sequencerSerialNumberMetadata } from './canonicalizeMetadata';

function slugify(str) {
      str = str.toLowerCase();
      str = str.replace(/[^a-z0-9 -]/g, ''); // Remove special characters
      str = str.replace(/\s+/g, '-'); // Replace spaces with hyphens
      return str;
}

function getFriendlyDatasetName(userName) {
    const randomChars = Math.random().toString(36).slice(8).toUpperCase()
    const date = new Date().toISOString().split('T')[0]
    let friendlyName = `${date} Dataset ${randomChars}`

    if (userName) {
        const firstName = userName.split(' ')[0]
        friendlyName = `${firstName}'s ${friendlyName}`
    }
    
    return friendlyName
}

function validateSequencerSerialNumber(sequencer, sequencerSerialNumber) {
    // Be permissive of sequencer serial number for unknown sequencers
    if (sequencer === '' || sequencer === 'unknown' || sequencer === 'other') {
        return true
    }

    // Consider an unset serial number valid
    if (sequencerSerialNumber === '') {
        return true
    }

    return (new RegExp("_?[A-Z0-9]{5,6}(-[A-Z0-9]{1,2})?$", "i").test(sequencerSerialNumber))
}

function validateFlowCellSerialNumber(flowCell, flowCellSerialNumber) {
    // Be permissive of flow cell serial number for unknown flow cells
    if (flowCell === '' || flowCell === 'unknown' || flowCell === 'other') {
        return true
    }

    // Consider an unset serial number valid
    if (flowCellSerialNumber === '') {
        return true 
    }

    return new RegExp("^[A-Z][0-9]{5}$", "i").test(flowCellSerialNumber)
}

function AddRunDialog(props) {
    const { open, setOpen, activeProject, userData, reloadRuns } = props;
    const { getAccessTokenSilently } = useAuth0();

    const defaultFriendlyName = getFriendlyDatasetName();

    const [isCreating, setIsCreating] = useState(false);
    const [platform, setPlatform] = useState('ont');
    const [friendlyName, setFriendlyName] = useState(defaultFriendlyName);
    const [name, setName] = useState(slugify(defaultFriendlyName));
    const [sequencer, setSequencer] = useState('');
    const [sequencerSerialNumber, setSequencerSerialNumber] = useState('');
    const [flowCell, setFlowCell] = useState('');
    const [flowCellSerialNumber, setFlowCellSerialNumber] = useState('');
    const [barcodingKit, setBarcodingKit] = useState('');
    const [libraryPrepKit, setLibraryPrepKit] = useState('');
    const [sequencerSerialNumberIsValid, setSequencerSerialNumberIsValid] = useState(true);
    const [flowCellSerialNumberIsValid, setFlowCellSerialNumberIsValid] = useState(true);
    const [description, setDescription] = useState('');

    useEffect(() => {
        const validSequencerSerialNumber = validateSequencerSerialNumber(sequencer, sequencerSerialNumber) 
        const validFlowCellSerialNumber = validateFlowCellSerialNumber(flowCell, flowCellSerialNumber)
        setSequencerSerialNumberIsValid(validSequencerSerialNumber)
        setFlowCellSerialNumberIsValid(validFlowCellSerialNumber)
    }, [flowCell, sequencer, flowCellSerialNumber, sequencerSerialNumber])

    useEffect(() => {
        setFriendlyName(getFriendlyDatasetName(userData?.data.attributes.name))
    }, [userData]);

    useEffect(() => {
        setName(slugify(friendlyName))
    }, [friendlyName]);

    useEffect(() => {
        if (!open) {
            return
        }

        const datasetName = getFriendlyDatasetName(userData?.data.attributes.name)

        setPlatform('ont');
        setFriendlyName(datasetName)
        setName(slugify(datasetName))
        setSequencer('');
        setSequencerSerialNumber('');
        setFlowCell('');
        setFlowCellSerialNumber('');
        setBarcodingKit('');
        setLibraryPrepKit('');
        setDescription('');
    }, [open, userData])

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        setOpen(false);
    };

    const handlePlatformUpdate = (event, newPlatform) => {
        if (newPlatform === null) {
            return;
        }

        setPlatform(newPlatform)
        setSequencer('');
        setSequencerSerialNumber('');
        setFlowCell('');
        setFlowCellSerialNumber('');
        setBarcodingKit('');
        setLibraryPrepKit('');
        setDescription('');
    };

    const createRun = useCallback(() => {
        setIsCreating(true)
        getAccessTokenSilently().then(accessToken => {
            const headers = new Headers({
              Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
            });

            const attributes = {
                name: name,
                sequencing_platform: platform,
                sequencer_name: sequencer,
            }

            if (sequencer !== '' && sequencerSerialNumber !== '') {
                attributes['minion_sn'] = `${sequencerSerialNumberMetadata[sequencer].prefix}${sequencerSerialNumber}`
            }
            
            if (flowCell !== '' && flowCell !== 'unknown' && flowCell !== 'other') {
                attributes['flowcell_name'] = flowCell
            }

            if (flowCell !== '' && flowCellSerialNumber !== '') {
                attributes['flowcell_serial_number'] = flowCellSerialNumber
            }

            if (barcodingKit !== '' && barcodingKit !== 'unknown' && barcodingKit !== 'other') {
                attributes['barcoding_kit'] = barcodingKit
            }

            if (libraryPrepKit !== '' && libraryPrepKit !== 'unknown' && libraryPrepKit !== 'other') {
                attributes['library_prep_kit'] = libraryPrepKit
            }

            if (description !== '') {
                attributes['description'] = description
            }

            return fetch(
                `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/projects/${activeProject.id}/sequencing_runs`,
                {
                  method: 'POST',
                  headers,
                    body: JSON.stringify(
                        {
                            data: {
                                type: "sequencing_run",
                                attributes: attributes
                            }
                        }
                    )
                },
              )
           })
           .then((res) => {
            if (!res.ok) {
              throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`)
            }
  
            return res.json()
          })
          .then((response) => {
              reloadRuns()
              setIsCreating(false)
              setOpen(false)
          })
          .catch(datadogRum.addError)
    }, [
        getAccessTokenSilently,
        name,
        sequencerSerialNumber,
        flowCell,
        flowCellSerialNumber,
        barcodingKit,
        libraryPrepKit,
        activeProject,
        platform,
        reloadRuns,
        sequencer,
        setOpen,
        description
    ])

    let formContent = platform === 'ont' ? (
        <CreateONTRunForm 
            sequencer={sequencer}
            setSequencer={setSequencer}
            sequencerSerialNumber={sequencerSerialNumber}
            setSequencerSerialNumber={setSequencerSerialNumber}
            sequencerSerialNumberIsValid={sequencerSerialNumberIsValid}
            flowCell={flowCell}
            setFlowCell={setFlowCell}
            flowCellSerialNumber={flowCellSerialNumber}
            setFlowCellSerialNumber={setFlowCellSerialNumber}
            flowCellSerialNumberIsValid={flowCellSerialNumberIsValid}
            barcodingKit={barcodingKit}
            setBarcodingKit={setBarcodingKit}
            libraryPrepKit={libraryPrepKit}
            setLibraryPrepKit={setLibraryPrepKit}
            platform='ont'
        />
    ) : (
        <CreateIlluminaRunForm 
            sequencer={sequencer}
            setSequencer={setSequencer}
            flowCell={flowCell}
            setFlowCell={setFlowCell}
            barcodingKit={barcodingKit}
            setBarcodingKit={setBarcodingKit}
            libraryPrepKit={libraryPrepKit}
            setLibraryPrepKit={setLibraryPrepKit}
            platform='illumina'
        />
    )

    const formIsValid = useMemo(() => {
        return sequencerSerialNumberIsValid && flowCellSerialNumberIsValid
    }, [sequencerSerialNumberIsValid, flowCellSerialNumberIsValid]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Add New Sequencing Dataset</DialogTitle>
            <DialogContent>
                <Box sx={{ paddingTop: "10px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ padding: "0px 10px" }}>
                                <ToggleButtonGroup
                                    fullWidth
                                    color="primary"
                                    value={platform}
                                    exclusive
                                    onChange={handlePlatformUpdate}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="ont" sx={{ fontSize: "15px", fontWeight: "bold" }}>
                                        <img src={ontLogo} alt="platform-logo" height="20px" style={{ marginLeft: '4px', marginRight: '10px' }} />
                                        ONT
                                    </ToggleButton>
                                    <ToggleButton value="illumina" sx={{ fontSize: "15px", fontWeight: "bold" }}>
                                        <img src={illuminaLogo} alt="platform-logo" height="20px" style={{ marginLeft: '4px', marginRight: '10px' }} />
                                        Illumina
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ padding: '15px 5px' }}>
                            <Box sx={{ padding: "5px" }}>
                                <TextField
                                    error={name === ''}
                                    required
                                    fullWidth
                                    variant="filled"
                                    label="Name"
                                    value={friendlyName}
                                    helperText={`Required. ${friendlyName === '' ? '' : `Identifier: ${name}`}`}
                                    onChange={(event) => {
                                        setFriendlyName(event.target.value);
                                    }}
                                />
                            </Box>
                        </Grid>
                        {formContent}
                        <Grid item xs={12}>
                            <Box sx={{ padding: "15px 10px" }}>
                                <TextField
                                    id="description-textarea"
                                    label="Description"
                                    placeholder="Short description of sequencing dataset"
                                    value={description}
                                    helperText="Optional"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    maxRows={2}
                                    onChange={(event) => {
                                        setDescription(event.target.value.slice(0, 100));
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{ marginBottom: "15px" }}>
                <Button variant="contained" color="primary" sx={{ marginRight: "20px" }} onClick={createRun} disabled={(name === '' || isCreating || !formIsValid)}>Create</Button>
                <Button color="primary" sx={{ marginRight: "20px" }} onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );

}


export default AddRunDialog;
